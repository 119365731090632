import React from 'react'
import Layout from '../../components/layout'
import { LongWritingCreator } from '../../components/ChallengeCreators'

const LongWritingCreatorPage = ({ challengeId }) => (
  <Layout>
    <LongWritingCreator challengeId={challengeId} />
  </Layout>
)

export default LongWritingCreatorPage
